import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <a href="/support" className="support-link">サポート</a>
    </footer>
  );
};

export default Footer;
