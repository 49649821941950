import React, { useState, useEffect } from 'react';
import './Modal.css';

const Modal = ({ isOpen, closeModal }) => {
  const [zipcode, setZipcode] = useState('');
  const [address, setAddress] = useState('');
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedTransport, setSelectedTransport] = useState([]);

  // 郵便番号で住所を検索する関数
  const searchAddressByZipcode = async (zip) => {
    if (!zip || zip.length < 7) return;

    try {
      const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`);
      const data = await response.json();

      if (data.results) {
        const fullAddress = `${data.results[0].address1} ${data.results[0].address2} ${data.results[0].address3}`;
        setAddress(fullAddress);
      } else {
        alert("住所が見つかりませんでした。");
      }
    } catch (error) {
      alert("住所検索中にエラーが発生しました。");
    }
  };

  // 郵便番号が7桁入力されたら自動で住所を検索
  useEffect(() => {
    if (zipcode.length === 7) {
      searchAddressByZipcode(zipcode);
    }
  }, [zipcode]);

  // 目的地の選択を管理
  const handleDestinationChange = (destination) => {
    setSelectedDestinations(prevState =>
      prevState.includes(destination)
        ? prevState.filter(d => d !== destination)
        : [...prevState, destination]
    );
  };

  // 交通手段の選択を管理
  const handleTransportChange = (transport) => {
    setSelectedTransport(prevState =>
      prevState.includes(transport)
        ? prevState.filter(t => t !== transport)
        : [...prevState, transport]
    );
  };

  if (!isOpen) return null;

  return (
    <div id="plan-modal" className="modal show">
      <div className="modal-content">
        <span className="close-button" onClick={closeModal}>
          &times;
        </span>
        <h2>旅行プランを作成</h2>
        <form className="input-form">
          {/* 基本情報セクション */}
          <h3>基本情報</h3>
          <div className="form-group">
            <label>出発地 (郵便番号)</label>
            <input
              type="text"
              placeholder="123-4567"
              className="form-control"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>住所</label>
            <input type="text" className="form-control" value={address} readOnly />
          </div>

          {/* 日程セクション */}
          <h3>日程</h3>
          <div className="form-group">
            <label>出発日</label>
            <input type="date" className="form-control" />
          </div>
          <div className="form-group">
            <label>帰着日</label>
            <input type="date" className="form-control" />
          </div>

          {/* 目的地セクション */}
          <h3>目的地 (複数選択可)</h3>
          <div className="form-group checkbox-group">
            <div
              className={`checkbox-option ${selectedDestinations.includes("北海道") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("北海道")}
            >
              北海道
            </div>
            <div
              className={`checkbox-option ${selectedDestinations.includes("青森") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("青森")}
            >
              青森
            </div>
            <div
              className={`checkbox-option ${selectedDestinations.includes("岩手") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("岩手")}
            >
              岩手
            </div>
            <div
              className={`checkbox-option ${selectedDestinations.includes("宮城") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("宮城")}
            >
              宮城
            </div>
            <div
              className={`checkbox-option ${selectedDestinations.includes("秋田") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("秋田")}
            >
              秋田
            </div>
            <div
              className={`checkbox-option ${selectedDestinations.includes("山形") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("山形")}
            >
              山形
            </div>
            <div
              className={`checkbox-option ${selectedDestinations.includes("福島") ? 'selected' : ''}`}
              onClick={() => handleDestinationChange("福島")}
            >
              福島
            </div>
          </div>

          {/* 詳細オプションセクション */}
          <h3>詳細オプション</h3>
          <div className="form-group">
            <label>予算 (円)</label>
            <input type="number" placeholder="50000" className="form-control" />
          </div>
          <div className="form-group checkbox-group">
            <div
              className={`checkbox-option ${selectedTransport.includes("電車") ? 'selected' : ''}`}
              onClick={() => handleTransportChange("電車")}
            >
              電車
            </div>
            <div
              className={`checkbox-option ${selectedTransport.includes("飛行機") ? 'selected' : ''}`}
              onClick={() => handleTransportChange("飛行機")}
            >
              飛行機
            </div>

            <div
              className={`checkbox-option ${selectedTransport.includes("フェリー") ? 'selected' : ''}`}
              onClick={() => handleTransportChange("フェリー")}
            >
              フェリー
            </div>

            <div
              className={`checkbox-option ${selectedTransport.includes("車") ? 'selected' : ''}`}
              onClick={() => handleTransportChange("車")}
            >
              車
            </div>
            <div
              className={`checkbox-option ${selectedTransport.includes("バス") ? 'selected' : ''}`}
              onClick={() => handleTransportChange("バス")}
            >
              バス
            </div>
          </div>

          {/* 人数セクション */}
          <div className="form-group">
            <label>人数</label>
            <select className="form-control">
              <option>1名</option>
              <option>2名</option>
              <option>3名以上</option>
            </select>
          </div>

          {/* ボタン */}
          <div className="form-actions">
            <button type="submit" className="blue-button">プランを検討する</button>
            <button type="button" className="green-button">AIにおまかせ</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
