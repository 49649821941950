import React, { useState } from 'react';
import './App.css';
import Header from './components/header';
import Slideshow from './components/Slideshow';
import Main from './components/Main';
import Modal from './components/Modal';
import Footer from './components/Footer';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="App">
      <Header />
      <Slideshow />
      <Main openModal={openModal} />
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
      <Footer />
    </div>
  );
}

export default App;
