import React, { useEffect } from 'react';
import './Slideshow.css';

const Slideshow = () => {
  useEffect(() => {
    // image1.jpg から image94.jpg までを自動的に生成
    const images = Array.from({ length: 94 }, (_, i) => `./images/image${i + 1}.jpg`);

    const slideshowElement = document.querySelector('.slideshow');

    // 各画像をスライドショーに追加
    images.forEach(image => { 
      const div = document.createElement('div');
      div.style.backgroundImage = `url(${image})`;
      slideshowElement.appendChild(div);
    });
  }, []);

  return (
    <div className="slideshow-container">
      <div className="slideshow"></div>
    </div>
  );
};

export default Slideshow;
